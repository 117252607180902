@import "../../../../global.scss";

.spendwallet{
  border-radius: 16px;
  overflow: hidden;
}

.spendwallet__emp{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 8px 20px;
  border-radius: 12px;
}