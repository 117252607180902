@import "../../../global.scss";

.payment{
  border-radius: 16px;
}


.payment{
  &__bank{
    width: 100%;
    background-color: white;
    border-radius: 16px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    transition: all .2s;
    border: 3px solid white;
    cursor: pointer;

    &-selected{
      border: 3px solid $secondary-light;
    }
  }

  &__bank:hover{
    box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 8px;
  }

  &__bank-disabled{
    width: 100%;
    background-color: #fff4f4;
    border-radius: 16px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    transition: all .2s;
    border: 3px solid #fff4f4;

  }

  &__international-wallet{
    width: 100%;
    background-color: white;
    border-radius: 16px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    transition: all .2s;
    border: 3px solid white;
  }

  &__international-wallet-disabled{
    width: 100%;
    background-color: #fff4f4;
    border-radius: 16px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    transition: all .2s;
    border: 3px solid #fff4f4;

  }
}

.payment__details{

  &-info{
    border-radius: 16px;
    padding: 15px;
    margin-bottom: 20px;
  }

  &-payment{
    background-color: #fafafa;
    border-radius: 16px;
    padding: 15px;
  }
}

.payment__act{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 12px;
  border-radius: 12px;
}

.payment__multiselect{
  margin: 15px 0px 15px 0px;
  border-radius: 16px;
  background-color: #fafafa;
  padding: 10px 10px 10px 30px;
}

.payment__receipt{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}