@import "../../../global.scss";

.activity{
  border-radius: 16px;
  overflow: hidden;
}

.card{
  scale: 1.2;
  max-width: 360px;
  width: 100%;
  height: 200px;
  border-radius: 16px;
  padding: 15px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card-cancelled{
  background-color: #faf1f1;
}