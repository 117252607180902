@import "../../../global.scss";

.expense{
  border-radius: 16px;
  overflow: hidden;

  &__head{
    padding: 20px;
    margin-bottom: 10px;
    background-color: #fafafa;
    border-radius: 16px;
  }
}


.expense__act{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 12px;
  border-radius: 12px;
}

.expense__multiselect{
  margin: 15px 0px 15px 0px;
  border-radius: 16px;
  background-color: #fafafa;
  padding: 10px 10px 10px 30px;
}

.expense__receipt{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}