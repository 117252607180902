@import "../../../../global.scss";

.vendorset{
  &__bank{
    width: 100%;
    background-color: white;
    border-radius: 16px;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    transition: all .2s;
    cursor: pointer;
  }

  &__bank:hover{
    box-shadow: rgba(0, 0, 0, 0.20) 0px 3px 8px;
  }
}